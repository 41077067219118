.FAQs{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.questions{
    margin: 70px;
    padding: 30px;
    box-shadow: 5px 5px 40px black;
}
.title{
    font-weight: bold !important;
    font-family: 'Montserrat', sans-serif !important;
    font-size: 1.2em !important;
}

@media (max-width: 991px) {
    .FAQs{
        height: auto;
    }
    .questions{
        padding: 0px;
        margin: 40px;
    }
}