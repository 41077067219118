.popNeckDesigns{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    overflow: hidden;
    padding: 20px 0px;
    height: 100vh;
}
.popNeckDesigns h1{
    padding: 30px 0px;
}

.neckDesign{
    display: flex;
    overflow: auto;
    height: fit-content;
    padding-left: 5px;
}

.neckDesign img{
    height: 400px;
    width: 800px;
    border: 5px solid rgb(253, 250, 250);
}

.popNeckDesigns h3{
    font-size: 1.8em;
}

@media (max-width: 991px){
    .popNeckDesigns{
        height: auto;
    }
    .neckDesign img{
        height: 200px;
        width: 400px;
    }
    .popNeckDesigns h1{
        padding: 30px 0px;
        font-size: 1.3em;
    }
    .popNeckDesigns h3{
        font-size: 1.2em;
        text-align: center;
        padding: 30px;
    }
}