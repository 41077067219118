.footer{
    display: flex;
    flex-direction: column;
}

.horizLine {
    display: flex;
    border-bottom: 2px solid black;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding-top: 70px;
}
.footerContent{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px;
}
.firstRow{
    width: 100%;
    display: flex;
}
.address{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.address h2{
    padding-bottom: 20px;
}

.contact{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    text-align: center;
}

.contactNumber{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 20px;
}

.secondRow{
    padding: 20px;
}

.footer-links{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 20px 0;
  }
  
.footer-links li{
    list-style: none;
    margin: 0 20px;
}

.footer-links li a{
    text-decoration: none;
    color: black;
    font-size: 1em;
    font-weight: 600;
    letter-spacing: 1px;
}

@media (max-width: 991px){
    .firstRow{
        flex-direction: column;
    }
    .address{
        width: 100%;
    }
    .contact{
        padding-top: 20px;
        width: 100%;
    }
    .contactNumber{
        padding: 15px 0 0 0;
    }
    .footer-links{
        text-align: center;
    }
    .address{
        text-align: center;
    }
}