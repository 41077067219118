@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
    scroll-behavior: smooth !important;
  }
.App{
  scroll-behavior: smooth;
}

.navbar{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 100px;
  background: linear-gradient(0deg, rgba(2,0,36,1) 0%, rgba(0,255,98,0) 0%, rgba(224,255,255,1) 87%);
}

.navbar .logo img{
  position: relative;
  height: 70px;
}

.navbar .nav-links{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 20px 0;
}

.navbar .nav-links li{
  list-style: none;
  margin: 0 20px;
}

.navbar .nav-links li a{
  text-decoration: none;
  color: black;
  font-size: 1em;
  font-weight: 600;
  letter-spacing: 1px;
  cursor: pointer;
}

.navbar .nav-links li a:hover{
  color: brown;
}


@media (max-width: 991px) {
  .navbar{
      padding: 10px 20px;
      flex-direction: column;
  }
  .navbar .nav-links li{
      list-style: none;
      margin: 0 10px;
  }

}

.homeBanner {
    display: flex;
    height: 50vh;
    overflow: hidden;
    justify-content: center;
}

.bannerContent{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 65%;
    padding-left: 30px;
}

.numberSec{
    display: flex;
}

.numberTxt{
    padding-bottom: 10px;
    font-size: 1.6em;
}
.mobileNumber{
    font-size: 2em;
    color: tomato;
}
.carousel {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35%;
    flex-direction: column;
}

.textA{
    font-size: 3em;
    padding-bottom: 30px;
}

.image {
    height: 300px;
    border-radius: 10px;
}

.arrows {
    display: flex;
    padding: 10px;
    padding-top: 40px;
}

.leftArrow{
    display: flex;
    font-size: 2rem;
    color: black;
    align-items: center;
    cursor: pointer;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    padding-right: 30px;
}

.rightArrow{
    display: flex;
    align-items: center;
    font-size: 2rem;
    color: black;
    cursor: pointer;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    padding-left: 30px;
}

.slide {
    opacity: 0;
    transition-duration: 1s ease;
}

.slide.active {
    opacity: 1;
    transition-duration: 1s;
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
}

@media (max-width: 991px) {
    .homeBanner{
      padding: 10px 20px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: auto;
    }
    .bannerContent{
        padding-left: 0px;
    }
    .carousel{
        padding-top: 20px;
    }
    .textA {
        font-size: 1.2em;
        text-align: center;
    }
    .aboutus{
        flex-direction: column;
        padding-right: 0px;
    }
    .aboutusTxt{
        font-size: 0.8em;
        text-align: center;
        padding-right: 0px;
    }
    .aboutusTxt br{
        display: none;
    }
    .numberTxt{
        text-align: center;
    }
  }
.principleArea{
    display: flex;
    justify-content: center;
    padding: 20px;
}

.principleContent{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    text-align: center;
}

.pimage{
    height: 90px;
    width: 90px;
}

.pheading{
    padding: 20px 0px 5px 0px;
}

@media (max-width: 991px) {
    .pimage{
        height: 60px;
        width: 60px;
    }
    .pheading{
        font-size: 0.5em;
    }
    .ptext{
        font-size: 0.8em;
    }
    .principleArea{
        padding: 20px 0 20px 0;
    }
}
.deliveryCheck{
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: center;
}

.deliveryImage{
    display: flex;
    width: 50%;
    align-items: center;
}
.deliveryImage img{
    width: 100%;
}

.deliveryTextArea{
    width: 50%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
.deliveryText{
    padding-bottom: 20px;
}
.deliveryResult{
    padding: 20px;
    color: green;
    font-size: 1.4em;
}
.deliveryResultNA{
    padding: 20px;
    color: red;
    font-size: 1.4em;
}


@media (max-width: 991px) {
    .deliveryCheck{
        height: auto;
    }
    .deliveryText{
        font-size: 0.8em;
    }
    .deliveryTextArea{
        text-align: center;
        padding: 10px;
    }
    .deliveryResultNA, .deliveryResult{
        font-size: 1em;
    }
}
.catalog{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    justify-content: center;
}

.productRates{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px;

}
.product{
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    border-radius: 20px;
}
.productITem{
    display: flex;
    justify-content: space-between;
    padding: 20px;
}
.productPrice{
    font-weight: bold;
    padding-left: 20px;
}
.category{
    padding-bottom: 40px;
    font-size: 1.8em;
}
.note{
    font-size: 0.9rem;
    font-weight: bold;
}
.pricingTitle{
    font-size: 2.2em;
    font-weight: bold;
}

@media (max-width: 991px) {
    .catalog{
        justify-content: start;
        height: auto;
        padding-top: 40px;
    }
    .productRates{
        flex-direction: column;
        margin: 0px;
    }
    .note ul{
        margin: 20px;
        text-align: center;
        list-style: none;
    }
    .note ul li{
        padding-bottom: 10px;
    }

}
.FAQs{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.questions{
    margin: 70px;
    padding: 30px;
    box-shadow: 5px 5px 40px black;
}
.title{
    font-weight: bold !important;
    font-family: 'Montserrat', sans-serif !important;
    font-size: 1.2em !important;
}

@media (max-width: 991px) {
    .FAQs{
        height: auto;
    }
    .questions{
        padding: 0px;
        margin: 40px;
    }
}
.popNeckDesigns{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    overflow: hidden;
    padding: 20px 0px;
    height: 100vh;
}
.popNeckDesigns h1{
    padding: 30px 0px;
}

.neckDesign{
    display: flex;
    overflow: auto;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding-left: 5px;
}

.neckDesign img{
    height: 400px;
    width: 800px;
    border: 5px solid rgb(253, 250, 250);
}

.popNeckDesigns h3{
    font-size: 1.8em;
}

@media (max-width: 991px){
    .popNeckDesigns{
        height: auto;
    }
    .neckDesign img{
        height: 200px;
        width: 400px;
    }
    .popNeckDesigns h1{
        padding: 30px 0px;
        font-size: 1.3em;
    }
    .popNeckDesigns h3{
        font-size: 1.2em;
        text-align: center;
        padding: 30px;
    }
}
.footer{
    display: flex;
    flex-direction: column;
}

.horizLine {
    display: flex;
    border-bottom: 2px solid black;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding-top: 70px;
}
.footerContent{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px;
}
.firstRow{
    width: 100%;
    display: flex;
}
.address{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.address h2{
    padding-bottom: 20px;
}

.contact{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    text-align: center;
}

.contactNumber{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 20px;
}

.secondRow{
    padding: 20px;
}

.footer-links{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 20px 0;
  }
  
.footer-links li{
    list-style: none;
    margin: 0 20px;
}

.footer-links li a{
    text-decoration: none;
    color: black;
    font-size: 1em;
    font-weight: 600;
    letter-spacing: 1px;
}

@media (max-width: 991px){
    .firstRow{
        flex-direction: column;
    }
    .address{
        width: 100%;
    }
    .contact{
        padding-top: 20px;
        width: 100%;
    }
    .contactNumber{
        padding: 15px 0 0 0;
    }
    .footer-links{
        text-align: center;
    }
    .address{
        text-align: center;
    }
}
