.deliveryCheck{
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: center;
}

.deliveryImage{
    display: flex;
    width: 50%;
    align-items: center;
}
.deliveryImage img{
    width: 100%;
}

.deliveryTextArea{
    width: 50%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
.deliveryText{
    padding-bottom: 20px;
}
.deliveryResult{
    padding: 20px;
    color: green;
    font-size: 1.4em;
}
.deliveryResultNA{
    padding: 20px;
    color: red;
    font-size: 1.4em;
}


@media (max-width: 991px) {
    .deliveryCheck{
        height: auto;
    }
    .deliveryText{
        font-size: 0.8em;
    }
    .deliveryTextArea{
        text-align: center;
        padding: 10px;
    }
    .deliveryResultNA, .deliveryResult{
        font-size: 1em;
    }
}