.catalog{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    justify-content: center;
}

.productRates{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px;

}
.product{
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    border-radius: 20px;
}
.productITem{
    display: flex;
    justify-content: space-between;
    padding: 20px;
}
.productPrice{
    font-weight: bold;
    padding-left: 20px;
}
.category{
    padding-bottom: 40px;
    font-size: 1.8em;
}
.note{
    font-size: 0.9rem;
    font-weight: bold;
}
.pricingTitle{
    font-size: 2.2em;
    font-weight: bold;
}

@media (max-width: 991px) {
    .catalog{
        justify-content: start;
        height: auto;
        padding-top: 40px;
    }
    .productRates{
        flex-direction: column;
        margin: 0px;
    }
    .note ul{
        margin: 20px;
        text-align: center;
        list-style: none;
    }
    .note ul li{
        padding-bottom: 10px;
    }

}