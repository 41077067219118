@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
    scroll-behavior: smooth !important;
  }
.App{
  scroll-behavior: smooth;
}

.navbar{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 100px;
  background: linear-gradient(0deg, rgba(2,0,36,1) 0%, rgba(0,255,98,0) 0%, rgba(224,255,255,1) 87%);
}

.navbar .logo img{
  position: relative;
  height: 70px;
}

.navbar .nav-links{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 20px 0;
}

.navbar .nav-links li{
  list-style: none;
  margin: 0 20px;
}

.navbar .nav-links li a{
  text-decoration: none;
  color: black;
  font-size: 1em;
  font-weight: 600;
  letter-spacing: 1px;
  cursor: pointer;
}

.navbar .nav-links li a:hover{
  color: brown;
}


@media (max-width: 991px) {
  .navbar{
      padding: 10px 20px;
      flex-direction: column;
  }
  .navbar .nav-links li{
      list-style: none;
      margin: 0 10px;
  }

}
