.homeBanner {
    display: flex;
    height: 50vh;
    overflow: hidden;
    justify-content: center;
}

.bannerContent{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 65%;
    padding-left: 30px;
}

.numberSec{
    display: flex;
}

.numberTxt{
    padding-bottom: 10px;
    font-size: 1.6em;
}
.mobileNumber{
    font-size: 2em;
    color: tomato;
}
.carousel {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35%;
    flex-direction: column;
}

.textA{
    font-size: 3em;
    padding-bottom: 30px;
}

.image {
    height: 300px;
    border-radius: 10px;
}

.arrows {
    display: flex;
    padding: 10px;
    padding-top: 40px;
}

.leftArrow{
    display: flex;
    font-size: 2rem;
    color: black;
    align-items: center;
    cursor: pointer;
    user-select: none;
    padding-right: 30px;
}

.rightArrow{
    display: flex;
    align-items: center;
    font-size: 2rem;
    color: black;
    cursor: pointer;
    user-select: none;
    padding-left: 30px;
}

.slide {
    opacity: 0;
    transition-duration: 1s ease;
}

.slide.active {
    opacity: 1;
    transition-duration: 1s;
    transform: scale(0.8);
}

@media (max-width: 991px) {
    .homeBanner{
      padding: 10px 20px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: auto;
    }
    .bannerContent{
        padding-left: 0px;
    }
    .carousel{
        padding-top: 20px;
    }
    .textA {
        font-size: 1.2em;
        text-align: center;
    }
    .aboutus{
        flex-direction: column;
        padding-right: 0px;
    }
    .aboutusTxt{
        font-size: 0.8em;
        text-align: center;
        padding-right: 0px;
    }
    .aboutusTxt br{
        display: none;
    }
    .numberTxt{
        text-align: center;
    }
  }