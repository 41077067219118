.principleArea{
    display: flex;
    justify-content: center;
    padding: 20px;
}

.principleContent{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    text-align: center;
}

.pimage{
    height: 90px;
    width: 90px;
}

.pheading{
    padding: 20px 0px 5px 0px;
}

@media (max-width: 991px) {
    .pimage{
        height: 60px;
        width: 60px;
    }
    .pheading{
        font-size: 0.5em;
    }
    .ptext{
        font-size: 0.8em;
    }
    .principleArea{
        padding: 20px 0 20px 0;
    }
}